import { Flex, Tag } from "antd";
import React from "react";
import styled from "styled-components";

import { colors, FormatText, Icon, Space } from "@gfw/corvus";

const StyledLink = styled(FormatText)`
  & a {
    color: ${colors.text.primary};
  }
`;

export function UserSearchResult({ ...user }) {
  return (
    <Flex align="center">
      <Space>
        <Icon fs="1.5em" type="user" />
        <Flex vertical>
          <FormatText fs="sm" fw="medium">
            <Space size="small">
              {user.name}
              {user.status === "INACTIVE" && (
                <Tag color={colors.disabled}>Inactive</Tag>
              )}
            </Space>
          </FormatText>
          <StyledLink fs="xs">{user.email}</StyledLink>
        </Flex>
      </Space>
    </Flex>
  );
}
