import { Upload } from "antd";
import { useMutation } from "react-query";
import React, { useState } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FormatText,
  Modal,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

function RevertMerge() {
  const [dumpList, setDumpList] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const onCancel = () => {
    setDumpList([]);
    setUploadDisabled(false);
  };

  const { mutateAsync: revertMerge, isLoading: isReverting } = useMutation(
    async () => {
      await apiClient.post(`/admin/profiles/revert`, {
        dumpOIds: dumpList.map(({ dumpOId }) => dumpOId),
      });
    },
    {
      onSuccess: () => {
        onCancel();
        notification.success({ message: `Stats  updated` });
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );
  const uploadDump = async (data) => {
    const formData = new FormData();
    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        );
        data.onProgress({ percent });
      },
    };
    formData.append("file", data.file);

    try {
      const response = await apiClient.post(
        `/admin/profiles/uploadDump`,
        formData,
        config,
      );
      setDumpList([...dumpList, response.data]);
      data.onSuccess();
    } catch (e) {
      data.onError(e.message);
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setUploadDisabled(newFileList.length === 2);
  };

  return (
    <Modal
      okDisabled={!uploadDisabled && dumpList.length != 2}
      okText="Revert"
      onCancel={onCancel}
      onOk={revertMerge}
      title="Revert Merge"
      trigger={
        <Button block icon="password" loading={isReverting} mb="md">
          Revert Merge
        </Button>
      }
    >
      <Upload.Dragger
        accept="application/json"
        customRequest={(data) => uploadDump(data)}
        disabled={uploadDisabled}
        height={"150px"}
        onChange={onChange}
      >
        <p className="ant-upload-text">
          Upload dump of the profiles before the merge
        </p>
      </Upload.Dragger>
      {dumpList.length === 2 && (
        <FormatText mt="md">
          We will try to revert the merge of {dumpList[0].name} and{" "}
          {dumpList[1].name}
        </FormatText>
      )}
    </Modal>
  );
}

export default RevertMerge;
