import { Flex, Table, Tag } from "antd";
import { useMutation, useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";
import saveAs from "file-saver";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FONT_WEIGHTS,
  Loading,
  NavigateLink,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { CursorListControls, useCursorPagination } from "@gfw/orion";
import { PROFILE_STATE, profileStateToString, ProfileTypes } from "@gfw/core";

const StyledName = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
`;

const GET_PROFILE_COUNTERPARTIES = gql`
  query ProfileCounterparties(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $id: ID!
    $where: ProfilesCounterpartiesWhereInput
  ) {
    ProfileCounterparties(
      first: $first
      after: $after
      before: $before
      last: $last
      id: $id
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        perTypes {
          type
          risk
          nextReviewAt
          lastReportAt
        }
        addedAt
        node {
          types
          state
          id
          name
        }
      }
    }
  }
`;

function ProfileCounterparties({ profile }) {
  const { id, name } = profile;

  const pagination = useCursorPagination();

  const { data = [], isLoading } = useQuery(
    ["counterparties", id, pagination.getGraphVariables()],
    async () => {
      const variables = {
        ...pagination.getGraphVariables(),
        id,
        where: { only_favorite: true },
      };

      const response = await apiClient.graphql(GET_PROFILE_COUNTERPARTIES, {
        variables,
      });

      if (response?.ProfileCounterparties) {
        pagination.setPageInfo(response.ProfileCounterparties.pageInfo);
        return response.ProfileCounterparties.edges;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const columns = [
    {
      title: "Name",
      dataIndex: ["node", "name"],
      key: "NAME",
      render: (name, profile) => (
        <NavigateLink noPadding to={`/profiles/${profile?.node?.id}/details`}>
          <StyledName>{name}</StyledName>
        </NavigateLink>
      ),
    },
    {
      title: "Type",
      dataIndex: ["node", "types"],
      key: "PROFILE_TYPE",
      render: (types) => {
        if (types.length < 3) {
          return ProfileTypes.getNamesFromIds(types).join(", ");
        }
        return `${ProfileTypes.getNamesFromIds(types.slice(0, 2)).join(
          ", ",
        )} and ${types.slice(2).length} more`;
      },
    },
    {
      title: "State",
      dataIndex: ["node", "state"],
      key: "STATE",
      render: (state) => {
        //unknown state
        let color = "grey";
        let label = profileStateToString(state);
        if (state === PROFILE_STATE.DRAFT) {
          color = "blue";
        } else if (state === PROFILE_STATE.PROPOSED) {
          color = "purple";
        } else if (state === PROFILE_STATE.INACTIVE) {
          color = "red";
        } else if (state === PROFILE_STATE.OFFICIAL) {
          color = "green";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: "Added at",
      dataIndex: ["addedAt"],
      key: "ADDED_AT",
    },
  ];

  const { mutateAsync: downloadCounterparties, isLoading: isDownloading } =
    useMutation(
      () => {
        return apiClient.get(`/admin/${id}/counterparties`, {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
        });
      },
      {
        onSuccess: (response) => {
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(file, `${name} - Counterparties.xlsx`, { autoBom: true });
        },
        onError: __DEPRECATED__ErrorHandler,
      },
    );

  return (
    <Flex gap={16} vertical>
      <Flex justify="flex-end">
        <Button
          icon="download"
          loading={isDownloading}
          onClick={downloadCounterparties}
          type="primary"
        >
          Download Counterparties
        </Button>
      </Flex>
      <div>
        {isLoading && <Loading size="small" />}
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          rowKey={(item) => item.node.id}
          rowSelection={false}
          size="middle"
        />
        <CursorListControls pagination={pagination} show={data.length > 0} />
      </div>
    </Flex>
  );
}

export default ProfileCounterparties;
