import { Table } from "antd";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Card, FormatText, NavigateLink } from "@gfw/corvus";
import { findRegionsBy } from "@gfw/core";

const GET_NEWS_PROVIDERS = gql`
  query NewsProviders {
    NewsProviders {
      id
      name
      homepage
      iso2
    }
  }
`;

function NewsProviderList() {
  const { data = [] } = useQuery(
    ["providersList"],
    async () => {
      const response = await apiClient.graphql(GET_NEWS_PROVIDERS);

      if (response?.NewsProviders) {
        return response.NewsProviders;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false, keepPreviousData: true },
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "NAME",
      render: (name, provider) => {
        return (
          <NavigateLink noPadding to={`/news/providers/${provider.id}`}>
            <FormatText ellipsis>{name}</FormatText>
          </NavigateLink>
        );
      },
    },
    {
      title: "Region",
      dataIndex: "iso2",
      key: "REGION_CODE",
      render: (iso2) => {
        const [region] = findRegionsBy(iso2.toUpperCase(), ["code"]);

        return region?.name;
      },
    },
    {
      title: "Home Page",
      dataIndex: "homepage",
      key: "HOME_PAGE",
      render: (homepage) => {
        return (
          <NavigateLink inNewWindow noPadding to={homepage}>
            {homepage}
          </NavigateLink>
        );
      },
    },
  ];

  return (
    <Card
      extra={
        <NavigateLink icon="add" to="/news/providers/new">
          Add News Provider
        </NavigateLink>
      }
      title={<FormatText fs="xl">News Providers</FormatText>}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="name"
        size="small"
      />
    </Card>
  );
}

export { NewsProviderList };
