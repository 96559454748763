import { CopyToClipboard } from "react-copy-to-clipboard";
import { Flex, Select, Table } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import {
  Button,
  Loading,
  Modal,
  NavigateLink,
  notification,
  Space,
} from "@gfw/corvus";
import { getEntries, PROFILE_STATE, USER_ROLE } from "@gfw/core";

import {
  AddExistingUserAction,
  InviteUserAction,
  RemoveInviteAction,
  RemoveUserFromProfileAction,
} from "./actions";

const { Option } = Select;

const SubText = styled("div")`
  font-size: 10px;
  color: grey;
`;

function CopyInviteUrl({ inviteUrl }) {
  return (
    <CopyToClipboard
      onCopy={() => notification.success({ message: "Copied to clipboard" })}
      text={inviteUrl}
    >
      <Button icon="copy" tooltip="Copy to clipboard">
        Copy Link
      </Button>
    </CopyToClipboard>
  );
}

function RenderStatus({ user, profile }) {
  const [showModal, setShowModal] = useState(false);
  const isInvited = user.status === "PENDING";

  const { mutateAsync: updateUserRole } = useMutation(
    () => {
      return apiClient.patch(`/profiles/${profile.oid}/users/${user.id}`, {
        role: userRole,
      });
    },
    {
      onSuccess: () => {
        notification.success({
          message: `User ${user.email} role has been updated`,
        });
        setShowModal(false);
      },
      onError: () => {
        notification.error({
          message: "Oops, something went wrong",
          description: `There was an error while trying to update the role for the user ${user.email}, please try again or contact support`,
        });
        setShowModal(false);
      },
    },
  );

  const [userRole, setUserRole] = useState(user.role);

  return (
    <>
      <Modal
        hideFooterBorder
        hideHeaderBorder
        okText="Confirm"
        onCancel={() => {
          setUserRole(user.role);
          setShowModal(false);
        }}
        onOk={updateUserRole}
        title="Update User Role"
        visible={showModal}
      >
        Do you wish to update {user.email} role to {userRole}?
      </Modal>
      <Select
        defaultValue={userRole}
        disabled={isInvited}
        onSelect={(role) => {
          try {
            setShowModal(true);
            setUserRole(role);
          } catch (error) {
            setUserRole(user.role);
            notification.error({
              message: "Oops, something went wrong",
              description:
                "There was an error while trying to update the role of the user",
            });
          }
        }}
        style={{ width: "100%" }}
        value={userRole}
        variant="borderless"
      >
        {getEntries(USER_ROLE).map(([key, value]) => {
          return (
            <Option key={key} value={value}>
              {key}
            </Option>
          );
        })}
      </Select>
    </>
  );
}

function ProfileUsers({ profile }) {
  const queryClient = useQueryClient();

  const isOfficial = profile.state === PROFILE_STATE.OFFICIAL;

  const { data: users, isLoading: loading } = useQuery(
    ["profileUsers"],
    async () => {
      let response = await apiClient.graphql(
        gql`
          query GetProfileUsers($id: ID!) {
            Profile(id: $id) {
              id
              users {
                userId
                name
                email
                role
                status
                id
                inviteUrl
                inviteId
                expirationDate
                invitedBy {
                  userId
                  name
                  email
                  status
                  isGFWAdmin
                }
              }
            }
          }
        `,
        {
          variables: { id: profile.id },
        },
      );
      return response?.Profile?.users;
    },
    { refetchOnWindowFocus: false },
  );

  function invalidateQuery() {
    queryClient.invalidateQueries("profileUsers");
  }

  const columns = [
    {
      title: "Name",
      dataIndex: ["name"],
      key: "NAME",
      render: (name, user) => {
        if (!name) {
          return "-";
        }
        return (
          <NavigateLink noPadding to={`/users/${user.id}/read`}>
            {name}
          </NavigateLink>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["email"],
      key: "EMAIL",
      render: (email, user) => {
        const { status, expirationDate } = user;
        const isInvited = status === "PENDING";
        return (
          <Flex vertical>
            {email}
            {isInvited && <SubText>expires {expirationDate}</SubText>}
          </Flex>
        );
      },
    },
    {
      title: "Invited By",
      dataIndex: ["invitedBy"],
      key: "INVITEDBY",
      render: (_, user) => {
        const { invitedBy } = user;
        if (invitedBy) {
          return invitedBy.isGFWAdmin
            ? `${invitedBy.email} (admin)`
            : invitedBy.email;
        }
      },
    },
    {
      title: "Role",
      dataIndex: ["role"],
      key: "ROLE",
      render: (_, user) => {
        return <RenderStatus profile={profile} user={user} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "STATUS",
    },
    {
      render: (_, user) => {
        const { status, id, inviteUrl, inviteId } = user;
        const isInvited = status === "PENDING";
        const renderActions = isInvited ? (
          <Space>
            <CopyInviteUrl inviteUrl={inviteUrl} />
            <RemoveInviteAction inviteId={inviteId} user={user} />
          </Space>
        ) : (
          <RemoveUserFromProfileAction
            onActionCompleted={() => {
              notification.success({
                message: `User ${user.email} was removed from profile`,
              });
              invalidateQuery();
            }}
            userOId={id}
          />
        );
        return <>{renderActions}</>;
      },
    },
  ];

  return (
    <Flex gap={16} vertical>
      <Space>
        <InviteUserAction onActionCompleted={invalidateQuery} />
        <AddExistingUserAction
          disabled={!isOfficial}
          onActionCompleted={() => {
            notification.success({ message: `User was added to profile` });
            invalidateQuery();
          }}
        />
      </Space>
      {loading && <Loading />}
      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
        pagination={false}
        rowKey="id"
        rowSelection={false}
        size="middle"
      />
    </Flex>
  );
}

export default ProfileUsers;
