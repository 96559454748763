import PropTypes from "prop-types";
import React from "react";

import { Box } from "../../../Box";
import { Field } from "../../../Field";
import { FormControl } from "../../../FormControl";
import { Question } from "..";
import { Radio } from "../../../Radio";
import { Select } from "../../../Select";
import { StyledFlex } from "../../questionnaire.styles";
import { Text } from "../../../Text";
import { toHashCode } from "@gfw/core";
import { useQuestionnaireQuestion } from "../Questionnaire";

function RadioGroupField({ label, subtitle, choices, helpText, ...props }) {
  const question = useQuestionnaireQuestion(props);
  const [questions, setQuestions] = React.useState([]);

  React.useEffect(() => {
    const choice = choices.find((choice) => choice.value === question.answer);
    if (choice && Array.isArray(choice.questions)) {
      setQuestions(choice.questions);
    } else {
      setQuestions([]);
    }
  }, [choices, question.answer]);

  return (
    <React.Fragment>
      <FormControl as="fieldset">
        {helpText ? (
          <>
            {subtitle && (
              <FormControl.Text color="text.secondary" fw="bold">
                {subtitle}
              </FormControl.Text>
            )}
            <FormControl.Label mb="sm">{label}</FormControl.Label>
            <FormControl.Text mb="sm" mt="0">
              {helpText}
            </FormControl.Text>
          </>
        ) : (
          <>
            {subtitle && (
              <FormControl.Text color="text.secondary" fw="bold">
                {subtitle}
              </FormControl.Text>
            )}
            <FormControl.Label mb="sm">{label}</FormControl.Label>
          </>
        )}
        <Radio.Group
          defaultValue={question.answer}
          isDisabled={question.isReadOnly}
          onChange={(event) => question.onAnswerUpdated(event.target.value)}
        >
          {choices.map((choice, index) => (
            <Radio
              key={`${toHashCode(choice.value)}--${index}`}
              value={choice.value}
            >
              {choice.label || choice.value}
            </Radio>
          ))}
        </Radio.Group>
      </FormControl>
      {questions.length > 0 && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </React.Fragment>
  );
}
RadioGroupField.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  choices: PropTypes.array,
  helpText: PropTypes.string,
};

function SelectField({ label, subtitle, choices, helpText, ...props }) {
  const question = useQuestionnaireQuestion(props);
  const [questions, setQuestions] = React.useState([]);

  React.useEffect(() => {
    const choice = choices.find((choice) => choice.value === question.answer);
    if (choice && choice.questions && Array.isArray(choice.questions)) {
      setQuestions(choice.questions);
    } else {
      setQuestions([]);
    }
  }, [choices, question.answer]);

  return (
    <React.Fragment>
      {question.isReadOnly ? (
        <Box>
          {subtitle && (
            <Text color="text.secondary" fw="bold">
              {subtitle}
            </Text>
          )}
          <Field label={label}>{question.answer || "-"}</Field>
        </Box>
      ) : (
        <FormControl>
          {helpText ? (
            <>
              {subtitle && (
                <FormControl.Text color="text.secondary" fw="bold">
                  {subtitle}
                </FormControl.Text>
              )}
              <FormControl.Label>{label}</FormControl.Label>
              <FormControl.Text mb="sm" mt="0">
                {helpText}
              </FormControl.Text>
            </>
          ) : (
            <>
              {subtitle && (
                <FormControl.Text color="text.secondary" fw="bold">
                  {subtitle}
                </FormControl.Text>
              )}
              <FormControl.Label>{label}</FormControl.Label>
            </>
          )}
          <Select
            defaultValue={question.answer}
            onSelect={(newValue) => question.onAnswerUpdated(newValue)}
            placeholder="Select..."
            size="lg"
          >
            {choices.map((choice, index) => (
              <Select.Option
                key={`${toHashCode(choice.value)}--${index}`}
                value={choice.value}
              >
                {choice.label || choice.value}
              </Select.Option>
            ))}
          </Select>
        </FormControl>
      )}
      {questions.length > 0 && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </React.Fragment>
  );
}
SelectField.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  choices: PropTypes.array,
  helpText: PropTypes.string,
};

function ChoiceField({ choices, threshold = 6, ...props }) {
  if (choices.length <= threshold) {
    return <RadioGroupField choices={choices} {...props} />;
  } else {
    return <SelectField choices={choices} {...props} />;
  }
}
ChoiceField.propTypes = {
  choices: PropTypes.array,
  threshold: PropTypes.number,
};

export default ChoiceField;
