import { Flex, Input, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import { useMutation } from "react-query";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import { FormatText, getColor, getFontSize, getSpace, Icon } from "@gfw/corvus";
import { PROFILE_STATE, profileStateToString, ProfileTypes } from "@gfw/core";

import {
  StyledAutoComplete,
  StyledFlex,
  StyledSpan,
  StyledTag,
  StyledText,
} from "./SearchBar.styles";

const SearchResult = styled(function SearchResult({
  name,
  id,
  LEINumber,
  state,
  onSelectResult,
  __typename,
  types,
  email,
  location,
  isLastItem,
  ...props
}) {
  const handleLinkClick = () => {
    onSelectResult && onSelectResult();
  };
  const isProfile = __typename === "Profile";
  const label = profileStateToString(state);
  const leiNumberSuffix = (
    <StyledTag color={getColor("gray.300")}>
      <StyledSpan>{LEINumber}</StyledSpan>
    </StyledTag>
  );
  const stateSuffix =
    (state === PROFILE_STATE.DRAFT && <Tag color="blue">{label}</Tag>) ||
    (state === PROFILE_STATE.PROPOSED && <Tag color="purple">{label}</Tag>) ||
    (state === PROFILE_STATE.OFFICIAL && <Tag color="green">{label}</Tag>) ||
    (state === PROFILE_STATE.INACTIVE && <Tag color="red">{label}</Tag>) ||
    null;

  return (
    <Link
      {...props}
      css={`
        color: inherit;
      `}
      onClick={handleLinkClick}
      to={`/${isProfile ? "profiles" : "users"}/${id}`}
    >
      <StyledFlex $isLastItem={isLastItem} align="center">
        <Icon mr="sm" type={isProfile ? "profile" : "user"} />
        <Flex vertical>
          <Flex>
            <FormatText fs="sm" fw="medium" pr="sm">
              {name}
            </FormatText>
            {LEINumber && leiNumberSuffix}
            {stateSuffix}
          </Flex>
          <StyledText>
            {isProfile
              ? `${ProfileTypes.getNamesFromIds(types).join(
                  ", ",
                )} - ${location}`
              : email}
          </StyledText>
        </Flex>
      </StyledFlex>
    </Link>
  );
})`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding: ${getSpace(2)};

  ${Icon} {
    opacity: 0.5;
    font-size: ${getFontSize("xl2")};
  }
`;

const SEARCH_QUERY = gql`
  query SearchQuery($query: String!, $state: Int, $first: Int) {
    startsWith: Profiles(
      where: { normalized_name_starts_with: $query }
      first: $first
    ) {
      edges {
        node {
          oid
          id
          name
          LEINumber
          location
          state
          types
          __typename
        }
      }
    }
    contains: Profiles(
      where: { normalized_name_contains: $query }
      first: $first
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          oid
          id
          name
          LEINumber
          location
          state
          types
          __typename
        }
      }
    }
    byState: Profiles(where: { state: $state }, first: $first) {
      edges {
        node {
          id
          name
          LEINumber
          location
          state
          types
          __typename
        }
      }
    }

    Users(where: { name_contains: $query }, first: $first) {
      edges {
        node {
          id
          name
          email
        }
      }
    }
  }
`;

function SearchBar() {
  const navigate = useNavigate();

  const [query, setQuery] = React.useState("");
  const [search, setSearch] = React.useState();
  const [results, setResults] = React.useState([]);
  const { mutateAsync: fetchResults } = useMutation((state) =>
    apiClient.graphql(SEARCH_QUERY, {
      variables: { query: search, state, first: 10 },
    }),
  );

  React.useEffect(() => {
    if (query?.length) {
      setSearch(query);
    } else {
      setSearch(null);
      setResults([]);
    }
  }, [query]);

  React.useEffect(() => {
    async function getSearchResults() {
      let state = 1000; // invalid state to avoid fetching all profiles

      if (search?.toLowerCase?.() === "proposed") {
        state = PROFILE_STATE.PROPOSED;
      }
      if (search?.toLowerCase?.() === "draft") {
        state = PROFILE_STATE.DRAFT;
      }

      const data = await fetchResults(state);

      const profilesNameStartsWith = data.startsWith?.edges?.map((edge) => {
        return edge.node;
      });

      const profilesNameContains = data.contains?.edges?.map((edge) => {
        return edge.node;
      });

      const profilesByState = data.byState?.edges?.map((edge) => {
        return edge.node;
      });

      const users = data.Users?.edges?.map((edge) => {
        return edge.node;
      });
      setResults(
        uniqBy(
          [
            ...(profilesNameStartsWith || []),
            ...(profilesNameContains || []),
            ...(profilesByState || []),
            ...(users || []),
          ],
          "id",
        ),
      );
    }

    search && getSearchResults();
  }, [search, fetchResults]);

  const profilesOrUsersComponent = results?.map((result, idx) => {
    const isLastItem = idx + 1 === results.length;
    let value = `${result.name} - ${result.id} - ${result.email}`;
    if (result?.__typename === "Profile") {
      value = `${result.name} - ${ProfileTypes.getNamesFromIds(
        result.types,
      ).join(", ")} - ${result.location}`;
    }
    return {
      value,
      key: result.id,
      typename: result?.__typename,
      label: (
        <SearchResult
          isLastItem={isLastItem}
          {...result}
          onSelectResult={() => setQuery("")}
        />
      ),
    };
  });

  const onSelect = (value, result) => {
    const isProfile = result.typename === "Profile";
    if (result?.key) {
      navigate(`/${isProfile ? "profiles" : "users"}/${result.key}`);
      setSearch("");
      setQuery("");
    }
  };

  const handleKeyEnter = (event) => {
    if (event.key === "Enter" && results.length > 0) {
      event.preventDefault();
      const firstResult = profilesOrUsersComponent[0];
      const { key, typename } = firstResult;
      const isProfile = typename === "Profile";
      navigate(`/${isProfile ? "profiles" : "users"}/${key}`);
      setSearch("");
      setResults([]);
    }
  };

  return (
    <StyledAutoComplete
      allowClear
      backfill
      listHeight={680}
      notFoundContent={
        !!search && results?.length === 0 ? "No results found" : false
      }
      onClear={() => {
        setSearch("");
        setQuery("");
      }}
      onSearch={setSearch}
      onSelect={onSelect}
      options={profilesOrUsersComponent}
      value={search}
    >
      <Input
        onKeyDown={handleKeyEnter}
        placeholder="Search for profiles or users..."
        prefix={<Icon color="gray.400" fs="md" mt="2px" type="search" />}
      />
    </StyledAutoComplete>
  );
}

export default SearchBar;
