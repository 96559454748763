import { Overlay } from "../Overlay";
import { useComposedRef } from "../../core";
import { useListBox } from "./ListBox";
import React from "react";

const ListBoxOverlay = React.forwardRef(
  function Component(props, forwardedRef) {
    const {
      refs: { overlay: overlayRef, button: buttonRef },
      isExpanded,
    } = useListBox();

    let ref = useComposedRef(overlayRef, forwardedRef);

    return (
      <Overlay
        isVisible={isExpanded}
        ref={ref}
        targetRef={buttonRef}
        {...props}
      />
    );
  },
);

ListBoxOverlay.defaultProps = {
  border: { color: "gfw.700" },
  placement: "bottom-start",
  offset: [0, 2],
};

export { ListBoxOverlay };
