import { Flex, Select, Table } from "antd";
import React, { useEffect } from "react";

import {
  Button,
  FormatText,
  NavigateLink,
  RiskEntitiesTags,
  RiskTag,
} from "@gfw/corvus";
import {
  DUE_DILIGENCE,
  getEnumValues,
  ProfileTypes,
  RISK_LEVELS,
} from "@gfw/core";

const parseCounterparties = (
  profileId,
  counterpartiesList,
  isDefaultValue = false,
  counterparties = {},
) => {
  counterpartiesList.forEach((cp) => {
    cp.perTypes.forEach((perType) => {
      const relationId = `${cp.node.id}${perType.type}`;

      if (!counterparties[relationId])
        counterparties[relationId] = {
          counterpartyId: cp.node.id,
          counterpartyOId: cp.node.oid,
          type: perType.type,
          name: cp.node.name,
        };
      counterparties[relationId][`${profileId}`] = true;
      counterparties[relationId][`${profileId}Risk`] = perType.risk;
      counterparties[relationId][`${profileId}Tags`] = perType.riskEntities;
      if (isDefaultValue) {
        counterparties[relationId].risk = perType.risk;
        counterparties[relationId].tags = perType.riskEntities;
      }
    });
  });
  return counterparties;
};

const buildDataSource = ({
  profileToRemove,
  profileToKeep,
  profileToRemoveCounterparties,
  profileToKeepCounterparties,
}) => {
  const counterpartiesFromProfileToRemove = parseCounterparties(
    profileToRemove.id,
    profileToRemoveCounterparties,
    false,
  );

  const counterpartiesDelta = parseCounterparties(
    profileToKeep.id,
    profileToKeepCounterparties,
    true,
    counterpartiesFromProfileToRemove,
  );

  const res = Object.keys(counterpartiesDelta)
    .map((key) => counterpartiesDelta[key])
    .filter((cp) => {
      if (cp[profileToKeep.id] && cp[profileToRemove.id]) {
        return (
          cp[`${profileToKeep.id}Risk`] !== cp[`${profileToRemove.id}Risk`] ||
          cp[`${profileToKeep.id}Tags`] !== cp[`${profileToRemove.id}Tags`]
        );
      }
      return false;
    });

  return res;
};

function CounterpartiesConflictsResolver({
  profileToRemove,
  profileToKeep,
  profileToRemoveCounterparties,
  profileToKeepCounterparties,
  nextStep,
  previousStep,
  setRelationsToUpdate,
  relationsToUpdate,
}) {
  const setRelationValues = (cp, { risk, tags }) => {
    setRelationsToUpdate(
      relationsToUpdate.map((relation) => {
        if (relation.counterpartyOId === cp.counterpartyOId) {
          const newValues = {
            ...relation,
          };
          if (risk !== undefined || risk !== null) newValues.risk = risk;
          if (tags) newValues.tags = tags;
          return newValues;
        }
        return relation;
      }),
    );
  };

  useEffect(() => {
    setRelationsToUpdate(
      buildDataSource({
        profileToRemove,
        profileToKeep,
        profileToRemoveCounterparties,
        profileToKeepCounterparties,
      }),
    );
  }, [
    profileToRemove,
    profileToKeep,
    setRelationsToUpdate,
    profileToRemoveCounterparties,
    profileToKeepCounterparties,
  ]);

  const columns = [
    {
      title: "Counterparty name",
      dataIndex: "name",
      key: "name",
      render: (name, cp) => (
        <NavigateLink noPadding to={`/profiles/${cp.counterpartyId}`}>
          {name}
        </NavigateLink>
      ),
    },
    {
      title: "Counterparty type",
      dataIndex: "type",
      key: "type",
      render: (type) => ProfileTypes.byId(type).name,
    },
    {
      title: profileToRemove.name,
      children: [
        {
          title: "Risk",
          dataIndex: `${profileToRemove.id}Risk`,
          key: `${profileToRemove.id}Risk`,
          render: (risk) => <RiskTag risk={risk} />,
        },
        {
          title: "Tags",
          dataIndex: `${profileToRemove.id}Tags`,
          key: `${profileToRemove.id}Tags`,
          render: (riskEntities) => (
            <RiskEntitiesTags riskEntities={riskEntities} />
          ),
        },
      ],
    },
    {
      title: profileToKeep.name,
      children: [
        {
          title: "Risk",
          dataIndex: "risk",
          key: `${profileToKeep.id}Risk`,
          render: (risk, cp) => (
            <Select
              defaultValue={risk || RISK_LEVELS.NOT_SET}
              onChange={(value) => {
                setRelationValues(cp, { risk: value });
              }}
              style={{ width: "100%", minWidth: 60 }}
            >
              {getEnumValues(RISK_LEVELS).map((level) => (
                <Select.Option key={level} value={level}>
                  {RISK_LEVELS[level]}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Tags",
          dataIndex: "tags",
          key: `${profileToKeep.id}Tags`,
          render: (riskEntities, cp) => (
            <Select
              defaultValue={riskEntities || []}
              mode="multiple"
              onChange={(value) => {
                setRelationValues(cp, { tags: value });
              }}
              style={{ width: "100%", minWidth: 60 }}
            >
              {DUE_DILIGENCE.getRiskEntities().map((tag) => (
                <Select.Option key={tag} value={tag}>
                  {tag.toUpperCase()}
                </Select.Option>
              ))}
            </Select>
          ),
        },
      ],
    },
  ];

  return (
    <Flex gap={16} vertical>
      <FormatText fs="md">
        Counterparties conflicts ({relationsToUpdate.length})
      </FormatText>
      <Table
        columns={columns}
        dataSource={relationsToUpdate}
        pagination={false}
        rowKey="name"
        size="small"
      />
      <Flex justify="space-around">
        <Button mr="15px" onClick={previousStep} width="150px">
          Previous
        </Button>
        <Button mr="15px" onClick={nextStep} type="primary" width="150px">
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default CounterpartiesConflictsResolver;
