import { useQueryClient } from "react-query";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Button, Modal, notification } from "@gfw/corvus";

function RemoveInviteAction({ user }) {
  const queryClient = useQueryClient();

  async function confirm() {
    try {
      await apiClient.delete(`/invites/${user.inviteId}`);
      notification.success({
        message: `Invite to ${user.email} was successfully deleted`,
      });
      queryClient.invalidateQueries("profileUsers");
    } catch (e) {
      notification.error({
        message: "Oops, something went wrong",
        description: `An error occurred while trying to delete the invite to ${user.email}`,
      });
    }
  }

  return (
    <Modal
      hideFooterBorder
      hideHeaderBorder
      onOk={confirm}
      title="Delete invite"
      trigger={
        <Button danger icon="delete">
          Delete
        </Button>
      }
    >
      {`Are you sure that you want to delete the invite to ${user.email}?`}
    </Modal>
  );
}

export { RemoveInviteAction };
