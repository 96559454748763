import { Card, Table, Tag } from "antd";
import { saveAs } from "file-saver";
import { useMutation, useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FONT_WEIGHTS,
  FormatText,
  NavigateLink,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import {
  BASE_SORT_COLUMN_DESCEND,
  datetime,
  PROFILE_STATE,
  profileStateToString,
  ProfileTypes,
  SORT_ORDER,
} from "@gfw/core";
import { CursorListControls, useCursorPagination } from "@gfw/orion";

const StyledSpace = styled(Space)`
  margin-bottom: 16px;
`;
const StyledName = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
`;

const formatDate = (dateObj) => {
  if (!dateObj) return "-";
  const [date, time] = new Date(dateObj).toISOString().split("T");
  const [hour, min] = time.split(":");
  return `${date} ${hour}H${min}`;
};

const PAGE_QUERY = gql`
  query ProfileListPage(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: ProfilesOrderInput
  ) {
    Profiles(
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          isPremium
          id
          name
          types
          state
          location
          lastConnectionAt
          stats
        }
      }
    }
  }
`;

// This page is shown at /profiles
function ProfilesList() {
  const pagination = useCursorPagination({
    data: {
      orderBy: { field: "NAME", order: "asc" },
    },
  });

  const { data = [], isLoading } = useQuery(
    ["profiles", pagination.getGraphVariables()],
    async () => {
      const variables = {
        ...pagination.getGraphVariables(),
      };
      const response = await apiClient.graphql(PAGE_QUERY, { variables });

      if (response?.Profiles) {
        pagination.setPageInfo(response.Profiles.pageInfo);
        return response.Profiles.edges;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const { mutateAsync: downloadAllProfiles, isLoading: isDownloading } =
    useMutation(
      () => {
        return apiClient.get("/admin/biggest-profiles", {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
        });
      },
      {
        onSuccess: (response) => {
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(file, `GFW-Profiles-${datetime.iso8601Date()}.xlsx`, {
            autoBom: true,
          });
        },
        onError: __DEPRECATED__ErrorHandler,
      },
    );

  function handleTableChange(_1, _filters, sorter) {
    const { columnKey, order } = sorter;

    pagination.resetCursor({
      orderBy: {
        field: columnKey,
        order: order === SORT_ORDER.DESCEND ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      },
    });
  }

  const antdColumns = [
    {
      title: "Name",
      sorter: true,
      dataIndex: ["node", "name"],
      key: "NAME",
      defaultSortOrder: "ascend",
      render: (name, profile) => (
        <NavigateLink noPadding to={`/profiles/${profile?.node?.id}/details`}>
          <StyledName>{name}</StyledName>
        </NavigateLink>
      ),
      showSorterTooltip: { title: "Sort on name" },
    },
    {
      dataIndex: ["node", "types"],
      title: "Types",
      key: "TYPES",
      render: (types) => ProfileTypes.getNamesFromIds(types).join(", "),
    },
    {
      dataIndex: ["node", "location"],
      title: "Location",
      key: "LOCATION",
    },
    {
      dataIndex: ["node", "lastConnectionAt"],
      title: "LastConnectionAt",
      key: "lastConnectionAt",
      render: (value) => formatDate(value),
    },
    {
      title: "State",
      dataIndex: ["node", "state"],
      key: "STATE",
      render: (state) => {
        //unknown state
        let color = "grey";
        let label = profileStateToString(state);
        if (state === PROFILE_STATE.DRAFT) {
          color = "blue";
        } else if (state === PROFILE_STATE.PROPOSED) {
          color = "purple";
        } else if (state === PROFILE_STATE.INACTIVE) {
          color = "red";
        } else if (state === PROFILE_STATE.OFFICIAL) {
          color = "green";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      ...BASE_SORT_COLUMN_DESCEND,
      dataIndex: ["node", "isPremium"],
      title: "Is Premium",
      key: "IS_PREMIUM",
      render(isPremium) {
        return isPremium && <Tag>{String(isPremium)}</Tag>;
      },
    },
    {
      ...BASE_SORT_COLUMN_DESCEND,
      dataIndex: ["node", "stats", "counterpartiesCount", "active"],
      title: "Counterparties",
      key: "COUNTERPARTIES",
      render(counterpartiesCount) {
        if (!counterpartiesCount) return null;
        return <FormatText>{String(counterpartiesCount)}</FormatText>;
      },
    },
  ];

  return (
    <Card>
      <StyledSpace>
        <NavigateLink icon="add" noPadding to="/profiles/new">
          New Profile
        </NavigateLink>
        <Button
          icon="download"
          loading={isDownloading}
          onClick={downloadAllProfiles}
          type="primary"
        >
          Download All Profiles
        </Button>
      </StyledSpace>
      <Table
        columns={antdColumns}
        dataSource={data}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={false}
        rowKey={(item) => item.node.id}
      />

      <CursorListControls pagination={pagination} show={data.length > 0} />
    </Card>
  );
}

export default ProfilesList;
